<template>
    <div>
        <v-layout>
            <div class="boxapp">

                <!-- boxapp head -->
                <div class="boxapp-head">
                    <a href="#">
                        <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd"/>
                    </a>
                    <h1>{{ project.type }}</h1>
                </div>

                <!-- boxapp body -->
                <div class="box-main">

                    <div class="section">
                        <!-- section head -->
                        <div class="head">
                            <h5>PASSO 2</h5>
                            <h4>Abrangência</h4>
                        </div>

                        <!-- list view -->
                        <div class="wrapper">
                     
                        </div>

                    </div>
                </div>
            </div>
            <div class="box-help-spacer"></div>
            <HelpBox :dados="tela2" />
        </v-layout>
        <FooterStep />
    </div>
</template>

<script type="plain/text">
import FooterStep from "@/components/FooterStep.vue"
import HelpBox from "@/components/HelpBox.vue"

export default {
    components: {
        HelpBox,
        FooterStep,
    },
    data() {
        return {
            tela2: {
                text:
                    'Clique no botão “+ Novo endereço” ao lado para iserir novos endereços.<br /><br />A abrangência do projeto qualem igitur hominem natura inchoavit? De ingenio eius in his disputationibus, non de moribus quaeritur.Qua tu etiam inprudens utebare non numquam.<br /><br /><h4 style="font-weight: 400;font-size: 24px;margin-bottom 15px">Tangibilidade</h4><br /><br />Clique no botão “+ Nova faixa” ao lado para inserir novas faixas de valores para doação.<br /><br />Dessa forma o doador pode sentir e tangibilizar o quanto estará ajudando no projeto/instituição.'
            },
            project: {
                type: "Documentos"
            }
        };
    }
};
</script>