<template>
  <div class="projeto">
    <Nav />
    <Icon />
    <div class="grid-box">
      <Breadcrumb :levels="breadcrumbLevels" />
      <div class="main-block" :class="pageOptions.FooterClass">
        <router-view
          :page-options="pageOptions"
          @changeLevels="changeLevels"
        />
      </div>
    </div>
  </div>
</template>
<script type="plain/text">
import Nav from "@/components/Nav.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Icon from "@/components/Icon.vue";
import PageOptions from "@/scripts/models/pageOptions.model";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import ProjectService from "@/scripts/services/project.service.js";
export default {
	data(){
		return{
			breadcrumbLevels: [{ text: "RH" }],
			pageOptions: new PageOptions(),
		}
	},
	components: {
		Nav,
		Breadcrumb,
		Icon
  	},
	
	//remove footer
	mounted() {
		this.addClass = this.additionalClass;
		this.changeClass();
	},
	methods: {
		changeClass() {
			this.addClass = "no-footer";
			this.$emit("input", this.addClass);
		},
		changeLevels(levels) {
		this.breadcrumbLevels = levels;
		}

	}
};
</script>